import serve from './serve'

export function pictureCate(params){//图库
    return serve({
        url:'v1/index/picture',
        method :'get',
        params:params,
    })
}
export function years(params){//图库年份
    return serve({
        url:'v1/index/years_color',
        method :'get',
        params:params,
    })
}

export function yearAll(params){//图库年份
    return serve({
        url:'v1/index/years',
        method :'get',
        params:params,
    })
}


export function swiperImg(params){//首页轮播图
    return serve({
        url:'v1/index/index',
        method :'get',
        params:params,
    })
}

export function getMessage(params){//首页公告
    return serve({
        url:'v1/common/getMessage',
        method :'get',
        params:params,
    })
}
export function cate(params){//图片分类接口
    return serve({
        url:'v1/picture/cate',
        method :'get',
        params:params,
    })
}


export function config(params){//公告信息
    return serve({
        url:'v1/common/config',
        method :'get',
        params:params,
    })
}

export function setMessage(params){//公告信息已读
    return serve({
        url:'v1/common/setMessage',
        method :'post',
        params:params,
    })
}

export function lotteryrecord(params){//开奖详情
    return serve({
        url:'v1/liuhe/record',
        method :'get',
        params:params,
    })
}


export function advertisementList(params){//详情广告列表
    return serve({
        url:'v1/ad/list',
        method :'get',
        params:params,
    })
}
export function picSeriesList(params){//系列
    return serve({
        url:'v1/pic_series/list',
        method :'get',
        params:params,
    })
}
export function picSeriesDetail(params){//系列详情
    return serve({
        url:'v1/pic_series/detail',
        method :'get',
        params:params,
    })
}
export function lottery_years(params){//彩种对应年份
    return serve({
        url:'v1/index/lottery_years',
        method :'get',
        params:params,
    })
}
export function lottery_years_color(params){//彩种对应年份
    return serve({
        url:'v1/index/lottery_years_color',
        method :'get',
        params:params,
    })
}
export function newJoin(params){//竞猜
    return serve({
        url:'v1/forecast/newJoin',
        method :'get',
        params:params,
    })
}
export function forecasBet(params){// 下注
    return serve({
        url:'v1/forecast/bet',
        method :'post',
        data:params,
    })
}
export function betList(params){// 下注列表
    return serve({
        url:'v1/forecast/bet_index',
        method :'post',
        data:params,
    })
}
export function forecastCancel(params){// 撤销下注
    return serve({
        url:'v1/forecast/cancel',
        method :'post',
        data:params,
    })
}

