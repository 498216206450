import serve from './serve'

export function listCorpusType(params){//资料
    return serve({
        url:'v1/corpus/listCorpusType',
        method :'get',
        params:params,
    })
}
export function listArticle(params){//资料列表
    return serve({
        url:'v1/corpus/listArticle',
        method :'get',
        params:params,
    })
}

export function infoArticle(params){//资料详情
    return serve({
        url:'v1/corpus/infoArticle',
        method :'get',
        params:params,
    })
}
export function comment(params){//热门评论
    return serve({
        url:'v1/comment/hot',
        method :'get',
        params:params,
    })
}
export function sonComment(params){//子评论
    return serve({
        url:'v1/comment/children',
        method :'get',
        params:params,
    })
}
export function commentList(params){//一级评论
    return serve({
        url:'v1/comment/list',
        method :'get',
        params:params,
    })
}

export function follow(params){//点赞
    return serve({
        url:'v1/corpus/follow',
        method :'post',
        params:params,
    })
}


export function getUserIndex(params){//资料用户
    return serve({
        url:'v1/user/getUserIndex',
        method :'get',
        params:params,
    })
}




