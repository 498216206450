import "core-js/modules/es.array.push.js";
import { resolveComponent as _resolveComponent, unref as _unref, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, isRef as _isRef, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-c034c1e2"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "tk-main-fans"
};
const _hoisted_2 = {
  class: "tk-fans"
};
const _hoisted_3 = {
  key: 1
};
const _hoisted_4 = {
  class: "tk-fans-picture"
};
const _hoisted_5 = ["src"];
const _hoisted_6 = {
  class: "fans-name"
};
const _hoisted_7 = ["onClick"];
import Dialog from '@/components/mode/message';
import { getBlacklist } from "@/utils/https/me";
import { setBlacklist } from '@/utils/https/user';
import { toRefs } from 'vue';

import { reactive, ref } from 'vue';
export default {
  __name: 'Blacklist',
  setup(__props) {
    let data = reactive({
      blacklist: [],
      page: 1,
      keywords: '',
      https: configUrlBase[0] + '/'
    });
    let {
      blacklist,
      https
    } = toRefs(data);
    let getBlacklists = (page, keywords = '') => {
      getBlacklist({
        page: page,
        keywords: keywords
      }).then(res => {
        if (res == undefined) return false;
        let datas = res.data.data.list;
        if (datas == 0) {
          finished.value = true;
        } else {
          loading.value = false;
          data.blacklist.push(...datas);
        }
      });
    };
    let setBlacklists = id => {
      //拉黑
      setBlacklist({
        id: id
      }).then(res => {
        if (res.status != '400') {} else {
          Dialog(res.data.message, '#07c160');
        }
      });
    };
    let cancel = (id, index) => {
      setBlacklists(id);
      data.blacklist.forEach((element, i) => {
        if (i == index) {
          data.blacklist.splice(index, 1);
        }
      });
    };
    const loading = ref(false);
    const finished = ref(false);
    const onLoad = () => {
      getBlacklists(data.page, data.keywords);
      data.page++;
    };
    let onSubmit = item => {
      //搜索
      data.page = 1;
      data.keywords = item;
      data.blacklist.length = 0;
      getBlacklists(data.page, data.keywords);
    };
    return (_ctx, _cache) => {
      const _component_search = _resolveComponent("search");
      const _component_headNav = _resolveComponent("headNav");
      const _component_empty = _resolveComponent("empty");
      const _component_van_list = _resolveComponent("van-list");
      return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(_component_headNav, {
        title: "我的黑名单"
      }, {
        tkCont: _withCtx(() => [_createVNode(_component_search, {
          returns: false,
          onOnSubmit: _unref(onSubmit)
        }, null, 8, ["onOnSubmit"])]),
        _: 1
      }), _createElementVNode("div", _hoisted_1, [_createVNode(_component_van_list, {
        loading: _unref(loading),
        "onUpdate:loading": _cache[0] || (_cache[0] = $event => _isRef(loading) ? loading.value = $event : null),
        finished: _unref(finished),
        "finished-text": "",
        onLoad: onLoad
      }, {
        default: _withCtx(() => [_createElementVNode("div", _hoisted_2, [_unref(blacklist).length == 0 ? (_openBlock(), _createBlock(_component_empty, {
          key: 0
        })) : (_openBlock(), _createElementBlock("ul", _hoisted_3, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(blacklist), (item, index) => {
          return _openBlock(), _createElementBlock("li", {
            key: index
          }, [_createElementVNode("div", _hoisted_4, [_createElementVNode("img", {
            src: item.user.avatar == '' ? require('@/assets/image/logo.png') : _unref(https) + item.user.avatar,
            alt: ""
          }, null, 8, _hoisted_5)]), _createElementVNode("div", _hoisted_6, _toDisplayString(item.user.nickname), 1), _createElementVNode("div", {
            class: "botton",
            onClick: $event => _unref(cancel)(item.user.id, index)
          }, "取消拉黑", 8, _hoisted_7)]);
        }), 128))]))])]),
        _: 1
      }, 8, ["loading", "finished"])])], 64);
    };
  }
};