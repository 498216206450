import serve from './serve'
import axios from 'axios';
import loading from '@/components/mode/loading'
export function picture(params){//图解详情
    return serve({
        url:'v1/picture/detail',
        method :'get',
        params:params,
    })
}
export function pictureVote(params){//图解投票
    return serve({
        url:'v1/picture/vote',
        method :'post',
        params:params,
    })
}


export function setFocus(params){//关注接口
    return serve({
        url:'v1/user/setFocus',
        method :'post',
        params:params,
    })
}

export function createComment(params){//添加评论
    return serve({
        url:'v1/comment/create',
        method :'post',
        params:params,
    })
}


export async function uploadFile(forms,zip=''){//图片上传
    loading()
    let form
    if(zip){
        form = forms
    }else{
        form = new FormData()
        forms.forEach(item => {
            form.append("file[]",item.file);
        })
    }

    let url =configUrlBase[0]+'/api/v1/comment/image'
    let token=localStorage.getItem('Authorization');
    let res =  await axios.post(url, form, {headers: {"Content-Type": "multipart/form-data",Authorization: token, }, })
    loading('close')
    return res

}
export function treasure(params){//图解详情
    return serve({
        url:'v1/treasure/list',
        method :'get',
        params:params,
    })
}

export function follow(params){//评论点赞
    return serve({
        url:'v1/comment/follow',
        method :'post',
        params:params,
    })
}

export function mystery(params){//玄机锦囊最新数据
    return serve({
        url:'v1/mystery/latest',
        method :'get',
        params:params,
    })
}

export function mysteryHistory(params){//玄机锦囊最新数据
    return serve({
        url:'v1/mystery/history',
        method :'get',
        params:params,
    })
}

export function lotteryList(params){//玄机锦囊最新数据
    return serve({
        url:'v1/open_lottery/list',
        method :'post',
        params:params,
    })
}


export function forward(params){//分享
    return serve({
        url:'v1/activity/forward',
        method :'get',
        params:params,
    })
}

export function nextLottery(params){//获取下一期开奖时间
    return serve({
        url:'v1/liuhe/next',
        method :'get',
        params:params,
    })
}


export function getMessageBadge(params){//获取下一期开奖时间
    return serve({
        url:'v1/common/getMessageBadge',
        method :'get',
        params:params,
    })
}


//


//