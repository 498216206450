
import details from '@/views/discover/details.vue';
import article from '@/views/discover/article.vue'
import tesc from '@/views/discover/tesc.vue'
import video from '@/views/discover/video.vue'
const discoverRoute = [

    {
        path: '/discover/details',
        name: 'discoverdetails',
        component: details
    },
    {
        path: '/discover/article',
        name: 'discoverarticle',
        component: ()=>import("@/views/discover/article.vue"),
        meta:{
            needLogin: true
        }
    },
    {
        path: '/discover/video',
        name: 'video',
        component: ()=>import("@/views/discover/video.vue"),
  
    },
    
    

]

export default discoverRoute