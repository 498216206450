import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'lib-flexible/flexible.js'
import { Lazyload } from 'vant';
const app = createApp(App)
app.use(router);
import 'video.js/dist/video-js.css'

import {config,yearAll,advertisementList,years,lottery_years} from '@/utils/https/page'
import {nextLottery} from '@/utils/https/https';
import { number_attr } from "@/utils/https/statistics"
app.config.globalProperties.$sayHello = () => { 
    // let avList = store.state.advertisementList;
    let avList = JSON.parse(localStorage.getItem('avList'));
    if(localStorage.getItem('lotteryType') == null){
        localStorage.setItem('lotteryType',2);
        localStorage.setItem('lotteryTypes',2);
    }
    if(sessionStorage.getItem('times') == null){
        sessionStorage.setItem('times', Date.now() + 1000 * 60 * 60 *2);
    }
    if(avList==null || avList.tiems < Date.now()){
        advertisementList({type:2}).then(res=>{
            if(res.data.status==20000){
                let adlist = res.data.data;
                let adlistData = {adlist,tiems: Date.now() + 1000 * 60 * 30 }
                // store.commit('handleAdvertisemen',adlist);     
                localStorage.setItem('avList',JSON.stringify(adlistData));
                localStorage.setItem('avDesc',JSON.stringify(res.data.list));
            }
        })
        years().then(res=>{
            let c = res.data.data;
            localStorage.setItem('yearColor',JSON.stringify(c))
        })
    }
    if(sessionStorage.getItem("yearList")==null) {
        lottery_years().then(res=>{
            if(res.data.status == 20000) {
          
                sessionStorage.setItem("yearList",JSON.stringify(res.data.data))
            }
        })
    }
    let nextLotterys = JSON.parse(localStorage.getItem('nextLottery'));
    if(nextLotterys==null || nextLotterys.tiems < Date.now()){
    
        nextLottery().then(res=>{//获取下一期的开奖数据
            if(res.status!=500){
                let datas = res.data.data;
                // let data = {datas,tiems:Date.now() + 1000 * 60 * 30 }
                localStorage.setItem('nextLottery',JSON.stringify(datas))
            }
        })
    }
    number_attr().then(res=>{
        if(res.data.status=='40000'){
            return false
        }
        let datas = res.data.data;
        localStorage.setItem('number_attr',JSON.stringify(datas))
    })
    if( localStorage.getItem('yearone')==null){
        yearAll().then(res=>{   
            if(res==undefined) return false
            let data = res.data.data;
            let reversed = data.reverse();
            localStorage.setItem('year', JSON.stringify(reversed));
            localStorage.setItem('yearone', reversed[0])
        })
    } 
    // localStorage.setItem('lotteryType',5)
}
    
    app.config.globalProperties.$filters = {
        format(value) {
    
            if (value>=10000) {
                let num = value/10000
                return num.toFixed(1) + '万'
               
            } else {
                return value
            }
        }
    }
    




// 注册时设置`lazyComponent`选项
app.use(Lazyload, {
lazyComponent: true,
});

import component from '@/utils/component.js'// 引入公共组件
app.use(store);
// app.use(loading);
app.use(component);

app.mount('#app');

