import { unref as _unref, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
import _imports_0 from '../../assets/image/closeb.png';
const _withScopeId = n => (_pushScopeId("data-v-58662b63"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "tk-header-box"
};
const _hoisted_2 = {
  key: 0,
  class: "appdownload"
};
const _hoisted_3 = ["src"];
const _hoisted_4 = {
  class: "desc"
};
const _hoisted_5 = {
  class: "down"
};
const _hoisted_6 = ["href"];
const _hoisted_7 = {
  class: "tk-header flex-between"
};
const _hoisted_8 = {
  class: "tk-header-info"
};
const _hoisted_9 = {
  class: "tk-header-logo"
};
const _hoisted_10 = {
  class: "tk-header-info"
};
const _hoisted_11 = ["href"];
import { onBeforeMount } from "vue";

import { useRouter } from 'vue-router';
import { ref, reactive } from 'vue';
export default {
  __name: 'header',
  props: {
    headLogo: '',
    config: {}
  },
  emits: ['serach', 'sehar', 'colse'],
  setup(__props, {
    emit: emits
  }) {
    const props = __props;
    const router = useRouter();
    let websiteName = ref(null);
    let serach = () => {
      emits('serach');
    };
    let sehar = () => {
      websiteName.value = JSON.parse(localStorage.getItem("config")).server_url;
      // emits('sehar')
    };

    let getQueryString = name => {
      let reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
      let r = window.location.search.substr(1).match(reg);
      if (r != null) {
        return decodeURIComponent(r[2]);
      }
      ;
      return null;
    };
    let tipsData = reactive({
      //弹窗提示
      downloadapp: true
    });
    onBeforeMount(() => {
      if (getQueryString('android') != null) {
        localStorage.setItem('downloadapp', false);
        tipsData.downloadapp = localStorage.getItem('downloadapp') == null ? 'false' : localStorage.getItem('downloadapp'); //判断是否显示
      } else {
        tipsData.downloadapp = 'false';
        // tipsData.downloadapp = localStorage.getItem('downloadapp')==null?true:localStorage.getItem('downloadapp');//判断是否显示
      }
    });

    let colse = () => {
      //关闭app下载
      localStorage.setItem('downloadapp', false);
      emits('colse');
      tipsData.downloadapp = 'false';
    };
    return (_ctx, _cache) => {
      const _component_van_icon = _resolveComponent("van-icon");
      return _openBlock(), _createElementBlock(_Fragment, null, [_createElementVNode("div", _hoisted_1, [_unref(tipsData).downloadapp != 'false' ? (_openBlock(), _createElementBlock("div", _hoisted_2, [_createElementVNode("img", {
        class: "logo",
        src: _unref(websiteName) + __props.config.logo,
        alt: ""
      }, null, 8, _hoisted_3), _createElementVNode("div", _hoisted_4, "下载" + _toDisplayString(__props.config.name) + "，看图更爽", 1), _createElementVNode("div", _hoisted_5, [_createElementVNode("a", {
        href: __props.config.download_url
      }, "下载", 8, _hoisted_6)]), _createElementVNode("img", {
        src: _imports_0,
        alt: "",
        onClick: _cache[0] || (_cache[0] = (...args) => _unref(colse) && _unref(colse)(...args))
      })])) : _createCommentVNode("", true), _createElementVNode("div", _hoisted_7, [_createElementVNode("div", _hoisted_8, [_createElementVNode("div", {
        class: "tk-serach",
        onClick: _cache[1] || (_cache[1] = (...args) => _unref(serach) && _unref(serach)(...args))
      }, [_createVNode(_component_van_icon, {
        name: "search"
      })])]), _createElementVNode("div", _hoisted_9, _toDisplayString(__props.config.name) + " ", 1), _createElementVNode("div", _hoisted_10, [_createElementVNode("div", {
        class: "tk-sehar",
        onClick: _cache[2] || (_cache[2] = (...args) => _unref(sehar) && _unref(sehar)(...args))
      }, [_createElementVNode("a", {
        href: _unref(websiteName)
      }, [_createVNode(_component_van_icon, {
        name: "service-o",
        size: "18"
      })], 8, _hoisted_11)])])])]), _createElementVNode("div", {
        class: _normalizeClass(_unref(tipsData).downloadapp != 'false' ? 'headers' : 'headerbb')
      }, null, 2)], 64);
    };
  }
};